/* .dynamic-background {
    height: 80vh;
    background: linear-gradient(45deg, #4ade80, #0f172a);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .content h2 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .content p {
    font-size: 1.5rem;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
   */

   .dynamic-background {
    height: 80vh;
    background: linear-gradient(45deg, #4ade80, #0f172a);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .content h2 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .content p {
    font-size: 1.5rem;
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Responsive Design */
  
  /* Tablets and small laptops (768px to 1024px) */
  @media (max-width: 1024px) {
    .dynamic-background {
      height: 70vh;
      padding: 15px;
    }
  
    .content h2 {
      font-size: 2.5rem;
    }
  
    .content p {
      font-size: 1.3rem;
    }
  }
  
  /* Mobile phones (320px to 767px) */
  @media (max-width: 767px) {
    .dynamic-background {
      height: 60vh;
      padding: 10px;
    }
  
    .content h2 {
      font-size: 2rem;
    }
  
    .content p {
      font-size: 1.1rem;
    }
  }
  