.tabs {
    margin: 40px auto;
    max-width: 900px;
  }
  
  .tab-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .tab-buttons button {
    padding: 10px 20px;
    background-color: #0f172a;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .tab-buttons button:hover {
    background-color: #4ade80;
  }
  
  .tab-content {
    text-align: center;
    font-size: 1.2rem;
  }
  
  .service:hover h3 {
    color: #4ade80;
  }
  
  .service:hover p {
    color: #4ade80;
  }
  
  .service:hover svg {
    transform: scale(1.2);
    transition: transform 0.3s ease;
  }
  html {
    scroll-behavior: smooth;
  }
  
  a {
    transition: color 0.3s;
  }
    