/* Basic Styles for Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #0f172a;
  color: white;
  position: relative;
}

.logo {
  font-size: 1.5rem;
  color: #4ade80;
}

/* Desktop Menu */
nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

nav ul li {
  cursor: pointer;
  font-weight: bold;
}

nav ul li:hover {
  color: #4ade80;
}

/* Hamburger Menu - Hidden on Desktop */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px;
}

/* Mobile Menu - Hidden by Default */
.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #0f172a;
  width: 100%;
}

.mobile-menu ul {
  list-style: none;
  padding: 10px;
  text-align: center;
}

.mobile-menu ul li {
  padding: 15px 0;
  border-bottom: 1px solid #4ade80;
  color: white;
  cursor: pointer;
}

.mobile-menu ul li:hover {
  background-color: #4ade80;
  color: #0f172a;
}

/* Responsive Styling */
@media (max-width: 768px) {
  /* Hide Desktop Menu */
  .desktop-menu {
    display: none;
  }

  /* Show Hamburger Menu */
  .hamburger {
    display: flex;
  }

  /* Show Mobile Menu when active */
  .mobile-menu {
    display: block;
  }
}
